import { isStringNotEmpty } from 'cms/utils/empty-exists'
// the library resource from core for use with kits or sets
import { library } from '@fortawesome/fontawesome-svg-core'

// named "selected" icons in the kit organized by prefix/family
import { all } from './dnv/all'
library.add(...all)

import { brands } from './dnv/brands'
import { solid } from './dnv/solid'
import { sharpRegular } from './dnv/sharp-regular'
import { sharpSolid } from './dnv/sharp-solid'
import { regular } from './dnv/regular'

// We import one entire font set to support using dynamic font names
const AVAILABLE_PREFIXES = ['far','fas']
const DEFAULT_PREFIX = 'far'

// all regular pro icons !! 2.5Mb !!
import * as farIcons from '@fortawesome/pro-regular-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
library.add(far)

// all solid pro icons !! 2.1Mb !!
// import * as fasIcons from '@fortawesome/pro-solid-svg-icons'
// import { fas } from '@fortawesome/pro-solid-svg-icons'
// library.add(fas)

export const getIcon = (prefix, name) => {
    if (!prefix) {
        prefix = DEFAULT_PREFIX
    }
    if (isStringNotEmpty(name) || name === null) {
        return null
    }
    // const icon = byPrefixAndName[prefix][name]
    let icon
    icon = farIcons[name]
    // if (prefix === 'fas') {
    //     icon = fasIcons[name]
    // } else {
    //     icon = farIcons[name]
    // }
    return icon?.icon
}

export {
    AVAILABLE_PREFIXES,
    all,
    regular,
    solid,
    brands,
    sharpRegular,
    sharpSolid,
}
