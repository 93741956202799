import { Logger } from 'cms/utils/logger'

/**
 * Update logic rules
 * @param {string[]} paths 
 * @param {LogicRule[]} logicRules 
 * @param {QuestionOptionRule[]} optionRules
 */
const updateLogicRules = (paths, logicRules, optionRules) => {
    Logger.debug(`[update-logic-rules] Rules for paths (${paths.join()}) that need to be updated`, optionRules)
    
    // first remove rules that have the same path ancestry
    // e.g. if logical rules ['A,B,C', 'A,B,X', 'C,D'] exist and new rules are given by A,B,
    // then this will result in a filtered rule list: ['C,D']
    const updatedRules = logicRules.filter(
        (logicRule) => {
            for (const logicRulePath of logicRule.paths) {
                for (const questionPath of paths) {
                    const isAncestor = logicRulePath.startsWith(questionPath)
                    if (isAncestor) {
                        return false
                    }
                }
            }

            return true
        }
    )

    // add logical rules using the supplied option rules
    for (const optionRule of optionRules) {
        const { action, targetElement } = optionRule

        updatedRules.push({
            target: targetElement,
            action,
            paths,
        })
    }

    Logger.debug('[update-logic-rules] Updated rules', updatedRules)

    return updatedRules
}

export { updateLogicRules }
