import { EventsStyleFeed } from "layout/homepage/feeds/feed-component/event/common/events-style-feed";
import { useFeed } from "layout/homepage/feeds/feed-component/use-feed";
import { useWindowSize } from "layout/homepage/useWindowSize";

const MeetingsFeed = (props) => {
    
    const { category, categoryName } = props;
    const term = undefined;
    const title= "Council and committees"
    const type = "model.meeting"
    const links = [
        {text: "All meetings", path: "/government-administration/council-meeting-calendar"},
        {text: "Council agendas", path: "/government-administration/meeting-agendas-and-minutes"},
    ]
    const quantity = 6
    const range = "future"
    const empty_message = "There are no upcoming meetings."
    const { isDesktop, isTablet } = useWindowSize();
    const customCssClass = isDesktop || isTablet ? "two-third council" : "council-column"
    
    const {models, loaded, pagination} = useFeed(
        { term, type, category, categoryName, quantity, range }
    )
    
    return (
        <>
            <EventsStyleFeed
                type={type}
                title={title}
                models={models}
                pagination={pagination}
                links={links}
                empty_message={empty_message}
                loaded={loaded}
                customCssClass={customCssClass}
            />
        </>
    );
};

export { MeetingsFeed };
