import React, { Fragment } from "react";
import { CommonTaskItem } from './common-task-item'
import { ServiceListItem } from "cms/components/icon-link-list/service-list-item";

const IconLinkListComponent = (props) => {
    
    const { component, field } = props || {}
    const { name } = field || {}
    const { icon_links = [], title, } = component || {}
    const isHomepage = name === 'services_list'
    // const isHomepage = false
    
    if (isHomepage) {
        return (
            <Fragment>
                <div className="common-tasks-items">
                    {icon_links.map((icon_link, index) => (
                        <Fragment key={index}>
                            <CommonTaskItem icon_link={icon_link} />
                        </Fragment>
                    ))}
                </div>
            
            </Fragment>
        )
    } else {
        return (
            <div className="link-list">
                {title &&
                    <h2 className="common-tasks-title">
                        {title}
                    </h2>
                }
                <div className="service-list-items">
                    {icon_links.map((icon_link, index) => (
                        <Fragment key={index}>
                            <ServiceListItem icon_link={icon_link} />
                        </Fragment>
                    ))}
                </div>
            </div>
        )
    }
}

IconLinkListComponent.displayName = 'IconLinkListComponent'
export {IconLinkListComponent}
