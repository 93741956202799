import React from "react";
import { Date } from "cms/fields/dates/date";
import { getFields } from "cms/utils/values-settings";
import { Config } from "config";

const HomeNoticeResultItem = (props) => {
    const { model } = props;
    const {
        title,
        description,
        path,
    } = model;
    const { date } = getFields(model);
    const dateFormat = Config.dateFormat.date_shortday;

    return (
        <div className="feed-component-item">
            <div className="feed-component-description">{description}</div>
            <div className="feed-component-content">
                <div className="feed-component-title">
                    <a href={path}>{title}</a>
                </div>
                <div className="feed-component-date feed-component-date-notice">
                    <Date field={date} format={dateFormat} />
                </div>
            </div>
        </div>
    );
};

export { HomeNoticeResultItem };
