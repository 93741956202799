import { FaWrapper } from '../../fa-wrapper'
import styles from '../dropdown-option.module.css'

/**
 * @param {SortOptionProps} props
 */
export function SortOption (props) {
    const { children, innerProps, isSelected, innerRef } = props

    return (
        <div ref={innerRef} {...innerProps} className={styles.container}>
            <FaWrapper icon="fa-regular fa-circle-dot fa-lg" color="#3D7737" show={isSelected} />
            <FaWrapper icon="fa-regular fa-circle fa-lg" show={!isSelected} />

            <div className={styles.label}>
                {children}
            </div>
        </div>
    )
}

/**
 * @typedef {import('instantsearch.js/es/connectors/sort-by/connectSortBy').SortByItem} SortByItem
 * @typedef {import('react-select').GroupBase<SortByItem>} Group
 * @typedef {import('react-select').OptionProps<SortByItem, false, Group} SortOptionProps
 */
