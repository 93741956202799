import { getRules } from 'cms/forms/utils/get-rules'
import { isGridQuestion } from 'cms/forms/utils/is-grid-question'
import { isLogicalQuestion } from 'cms/forms/utils/is-logical-question'
import { isArray, isArrayEmpty, isString } from 'cms/utils/empty-exists'
import { produce } from 'immer'
import { applyLogicRules } from './rules/apply-logic-rules'
import { ensureTargetElementExists } from './rules/ensure-target-element-exists'
import { extractDependencies } from './rules/extract-dependencies'
import { initLogicRules } from './rules/init-logic-rules'
import { MaterializedPath } from './rules/materialized-path'
import { updateLogicRules } from './rules/update-logic-rules'
import { Logger } from 'cms/utils/logger'

/**
 * @param {FormState} state 
 * @param {FormDispatchAction} action
 * @returns {FormState}
 */
const FormReducer = (state, action) => {

    /**
     * 
     * @param {string} questionGuid 
     */
    const getQuestion = (questionGuid) => {
        const question = state.schemaForm.questions.find(
            (question) => question.guid === questionGuid
        )

        if (!question) {
            Logger.warn(`[form-reducer] Cannot find question with guid ${questionGuid}`)
        }

        return question
    }

    /**
     * Recursively resolves all questions that may have a value set after being
     * shown including the initial question.
     * 
     * @param {FormQuestion} initialQuestion 
     * @param {QuestionOptionRule[]=} newRules 
     * @param {boolean} skipInitialGridQuestion
     */
    const resolveQuestionRules = (initialQuestion, newRules = [], skipInitialGridQuestion = true) => {
        let shownQuestionsWithValue = [initialQuestion]
        let currentRules = state.rules

        /** @type {QuestionOptionRule[]} */
        let rulesToAdd = newRules

        while (shownQuestionsWithValue.length) {
            const question = shownQuestionsWithValue.pop()

            // only process logical questions
            if (!isLogicalQuestion(question)) {
                continue
            }

            const isInitialQuestion = initialQuestion.guid === question.guid

            /** @type {import('cms/forms/utils/is-logical-question').LogicalQuestion} */
            const { paths, value, options } = question

            // if the initial question is a grid question, then don't
            // get rules; there is a dedicated mechanism for that
            const hasOwnRules = isInitialQuestion && isGridQuestion(question)

            if (hasOwnRules && skipInitialGridQuestion) {
                continue
            }

            // this is an inversion of the logic above
            if (!hasOwnRules) {
                const isStringOrArray = isString(value) || isArray(value)
                const selectedOptions = isStringOrArray ?
                    value :
                    Object.keys(value)

                rulesToAdd = getRules(
                    selectedOptions,
                    options
                )
            }

            ensureTargetElementExists(rulesToAdd, question.guid)

            currentRules = updateLogicRules(paths, currentRules, rulesToAdd)
            const questionsToResolve = applyLogicRules(
                state.schemaForm.questions,
                currentRules
            )

            shownQuestionsWithValue.push(...questionsToResolve)
        }

        return currentRules
    }

    switch (action?.type) {
        case "UPDATE_CURRENT_FORM": {
            /** @type {{schemaForm: FormState['schemaForm']; guid: string; reset: boolean}} */
            const { schemaForm, guid, reset } = action
            const { type, name, updated_at, version, steps } = schemaForm || {}
            let { questions } = schemaForm || {}

            // extract dependencies
            const { deps, hasRules } = extractDependencies(questions)

            const questionsWithPaths = produce(questions, ((draft) => {
                const mp = new MaterializedPath(deps)

                for (const question of draft) {
                    const isDependent = deps.has(question.guid)
                    question.isDependent = isDependent

                    // mark the dependency path (e.g., A,B,C) on the question
                    // this is used to determine which question rules should update
                    question.paths = mp.getPath(question.guid)
                        .filter((path) => path.length)
                        // reverse so ancestry reads from left to right => A,B,C
                        .map((path) => path.reverse().join(','))
                }
            }))

            const rules = initLogicRules(questionsWithPaths, deps, hasRules)
            applyLogicRules(questionsWithPaths, rules)

            if (reset) {
                state.resetCount++
            }

            state.schemaForm = {
                type,
                form_id: guid,
                name,
                updated_at,
                version,
                steps,
                questions: questionsWithPaths,
            }
            state.validatedQuestions = []
            state.activeStep = 1
            state.rules = rules

            break
        }

        case "UPDATE_QUESTION_VALUE": {
            /** @type {{ guid: string }} */
            const { guid, newValue } = action

            const initialQuestion = getQuestion(guid)
            if (!initialQuestion) {
                break
            }

            initialQuestion.value = newValue
            const currentRules = resolveQuestionRules(initialQuestion, [], true)

            state.rules = currentRules
            break
        }

        case "UPDATE_QUESTION_RULES": {
            /** @type {{ guid: string; newRules: QuestionOptionRule[] }} */
            const { guid, newRules } = action

            const initialQuestion = getQuestion(guid)
            if (!initialQuestion) {
                break
            }

            const currentRules = resolveQuestionRules(initialQuestion, newRules, false)

            state.rules = currentRules
            break
        }

        case "SET_QUESTION_ERROR": {
            /** @type {{guid: string; errors: string[]}} */
            const { guid, errors } = action

            const question = getQuestion(guid)
            if (!question) {
                break
            }

            question.errors = errors
            question.isValid = isArrayEmpty(errors)
            break
        }

        case "SET_STEP": {
            /** @type {{step: number}} */
            const { step } = action

            state.activeStep = step
            break
        }

        case "SET_LOADING": {
            /** @type {{loading: boolean}} */
            const { loading } = action

            state.loading = loading
            break
        }

        case "SET_QUESTION_LABELS": {
            /** @type {{guid: string, labelDef: Record<string, string>}} */
            const { guid, labelDef } = action

            const question = getQuestion(guid)
            if (!question) {
                break
            }

            question.labels = {
                ...question.labels,
                ...labelDef
            }

            break
        }

        case "SET_VALIDATED_QUESTIONS": {
            /** @type {{validatedQuestions: string[]}} */
            const { validatedQuestions } = action

            state.validatedQuestions = validatedQuestions
            break
        }

        default: {
            Logger.warn(`Unhandled action ${action?.type}`)
            break
        }
    }

    return state
}

export { FormReducer }
