/*
 * Copyright (C) 2024 SimpliCity Digital Inc - All Rights Reserved
 */
import { useExternalScript } from 'cms/hooks/use-external-script'
import { Config } from 'config'
import { CmsConfig } from 'cms/config'
// import { Logger } from 'cms/utils/logger'

const UserWayPlugin = () => {

    // Set Config - use customer config if set, or cms config defaults
    const attributes = {
        src: CmsConfig.Userway.src,
        'data-account' : Config.Userway.account || CmsConfig.Userway.account
    }
    const { src: script } = attributes
    // const status = useExternalScript(script, attributes)
    useExternalScript(script, attributes)
    // const scriptReady = status === 'ready'
    // if (scriptReady) {
    //     Logger.debug(`loaded UserWay script plugin`)
    // }
    return (
        <></>
    )
}
UserWayPlugin.displayName = 'UserWayPlugin'
export { UserWayPlugin }
