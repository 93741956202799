import React, { Fragment } from "react";
import { useSimpliCity } from "cms/hooks/use-simplicity";
import { Component } from "cms/fields/component";

const ServicesList = () => {
    const title = "Common tasks";
    const allServices = "All online services";
    const allServicesPath = "/programs-and-services/online-payments-and-services";

    const { webpage, model, fields } = useSimpliCity();
    const { services_list } = fields || {};

    return (
        <Fragment>
            <div className="feed-component-header-clear">
                <div className="feed-component-title-wrapper">
                    <h2 className="feed-component-title-link">{title}</h2>
                    <div className="feed-component-title-underline"></div>
                </div>
                <div className="feed-component-header-items">
                    <div className="feed-component-header-item">
                        <a href={allServicesPath}>{allServices}</a>
                    </div>
                </div>
            </div>
            {services_list && (
                <Fragment>
                    <Component field={services_list} model={model} webpage={webpage} />
                </Fragment>
            )}
        </Fragment>
    );
};
export { ServicesList };
