import React, { useEffect, useState } from 'react'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { SimpliCitySDK } from 'cms/sdk'
import { isValidGuid } from 'cms/utils/empty-exists'
import { Logger } from 'cms/utils/logger'

function RichTextImage (props) {

    const { token } = useAuth()
    const {
        image: _image,
        // settings,
        orientation
    } = props || {}

    const [image, setImage] = useState()
    const { alt, url } = image || {}
    // const { options } = settings || {}
    // const { position = 'center center' } = options || {}
    // const image_style = {
    //     backgroundImage: `url(${url})`,
    //     backgroundPosition: position,
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: 'cover',
    // }

    useEffect(() => {
        const fetchImage = async (token, guid) => {
            if (isValidGuid(guid)) {
                let current_image = await SimpliCitySDK.images.findOne(token, guid)
                if (current_image && current_image.guid) {
                    setImage(current_image)
                }
            } else {
                setImage(undefined)
            }
        }
        Logger.log(`Rich TextImage... ${_image}`)
        fetchImage(token, _image)
    }, [token, _image])

    return (
        <>
            {image &&
                <img
                    className={`${orientation}`}
                    src={url}
                    alt={alt}
                />}
        </>
    )
}

export { RichTextImage }
