import React, { useEffect, useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { RichText } from 'cms/rich-text'
import { ListImage } from 'cms/components/image-list/image'
import { ListLink } from 'cms/components/image-list/link'
import { isRichTextNotEmpty, isStringNotEmpty, objectHasPropertyString } from "cms/utils/empty-exists";
import { Logger } from 'cms/utils/logger'

function SimplicityImageListItem (props) {
    
    const { item } = props
    const {
        heading = '',
        description = '',
        image: image_guid,
        link: link_guid,
    } = item || {}
    
    const { token } = useAuth()
    const [image, setImage] = useState()
    const [link, setLink] = useState()

    useEffect(() => {
        const fetchData = async () => {
            Logger.debug(`load image ${image_guid}`)
            if (!image) {
                if (image_guid) {
                    const img = await SimpliCitySDK.images.findOne(token, image_guid)
                    setImage(img)
                }
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        const fetchData = async () => {
            Logger.debug(`load link ${link_guid}`)
            if (!link) {
                if (link_guid) {
                    const _link = await SimpliCitySDK.link.findOne(token, link_guid)
                    setLink(_link)
                }
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={'image-list-item'}>
            
            {image &&
                <ListImage image={image} />
            }
            
            <div>
                
                {isStringNotEmpty(heading) &&
                    <h3>{heading}</h3>
                }
                
                {isRichTextNotEmpty(description) &&
                    <RichText richtext={description} />
                }
                
                {objectHasPropertyString(link, 'guid') &&
                    <ListLink
                        link={link}
                    />
                }
            </div>
        </div>
    )
}
export default SimplicityImageListItem
