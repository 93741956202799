
import  {
    faCaretUp,
} from '@awesome.me/kit-5f107d6f8b/icons/sharp/regular'

const sharpRegular = {
    faCaretUp,
}

export {
    sharpRegular,
}
