import dayjs from "dayjs";
import { CmsConfig } from "cms/config";
import { getPrimaryDate, hasPrimaryDate } from "cms/models/helpers";

const ResultItem = (props) => {
    const { model } = props;
    const { title, description, path } = model;
    const hasDate = hasPrimaryDate(model);
    let primary_date;
    if (hasDate) {
        const dateData = getPrimaryDate(model);
        // const updated_at = model?.updated_at
        // const { date = updated_at } = dateData || {}
        const { date } = dateData || {};
        primary_date = date ? dayjs(date).format(CmsConfig.dates.display.date_fullday) : undefined;
    }

    return (
        <div className="feed-result-row">
            <h3 className="title">
                <a href={path}>{title}</a>
            </h3>
            {primary_date && <div className="field field-name-post-date">{primary_date}</div>}
            <div className="feed-result-description">
                <p>{description}</p>
            </div>
        </div>
    );
};

export { ResultItem };
