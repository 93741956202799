import { CircularProgress } from "@mui/material";
import { HomeEventResultItem } from "layout/homepage/feeds/feed-component/event/common/event-result-item";
import { SkipDuringPrerender } from "cms/shared/hyrdation/avoid-prerender";
import { isArrayNotEmpty } from "cms/utils/empty-exists";

const EventsStyleFeed = (props) => {
    const {
        type,
        title,
        models,
        links = [],
        empty_message,
        loaded,
        customCssClass
    } = props;

    // const eventType = ["model.event", "model.meeting"];
    const hasModels = loaded && isArrayNotEmpty(models);

    return (
        <div className={`feed-component-container home ${customCssClass}`}>
            <div className="feed-component-header-clear">
                <div className="feed-component-title-wrapper">
                    <h2 className="feed-component-title-link">{title}</h2>
                    <div className="feed-component-title-underline"></div>
                </div>
                <div className="feed-component-links-wrapper">
                    {links && links.map((link, index) => (
                        <div key={index} className="feed-component-link-item">
                            <a href={link.path}>{link.text}</a>
                        </div>
                    ))}
                </div>
            </div>
            <div className="feed-component-panel-clear">
                <div style={{ height: "100%" }} className="feed-component-panel-inner">
                    <SkipDuringPrerender>
                        {!loaded && (
                            <div className="spinner-container">
                                <CircularProgress />
                            </div>
                        )}
                        {loaded && !hasModels && <>{empty_message}</>}
                        {models &&
                            models.map((model, index) => {
                                const { guid } = model;
                                return <HomeEventResultItem type={type} key={`${guid}${index}`} model={model} />;
                            })}
                    </SkipDuringPrerender>
                </div>
            </div>
        </div>
    );
};

export { EventsStyleFeed };
