// import { faArrowRight, faMinus, faPlus } from 'icons'
import { icons } from "icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isArrayNotEmpty } from "cms/utils/empty-exists";
import { NavLink, useLocation } from "react-router-dom";

/**
 * @type {import('react').FC<MenuItemProps>}
 */
const MenuItem = (props) => {
    const { children, openMenus, setOpenMenus, item, guid, siblings } = props;
    const { children: itemChildren, path, nav_title } = item || {};

    const loc = useLocation();
    const isActive = loc.pathname === path;

    const isOpen = openMenus.includes(guid);
    const icon = isOpen ? icons.regular.faMinus : icons.regular.faPlus;

    const toggleOpen = () => {
        const siblingGuid = checkSiblingGuids(guid, siblings);

        setOpenMenus((prev) => {
            let prevOM = prev;
            if (siblingGuid.length > 0 && siblingGuid !== guid) {
                prevOM = prev.filter((i) => i !== siblingGuid);
            }
            const next = isOpen ? prevOM.filter((item) => item !== guid && item !== siblingGuid) : [...prevOM, guid];

            return next;
        });
    };

    const checkSiblingGuids = (guid, tree) => {
        let omRemove = "";
        const isSameLevel = tree.filter((t) => t.guid === guid);
        if (isSameLevel.length > 0) {
            const treeGuids = tree.map((t) => t.guid);

            for (let g of treeGuids) {
                for (let o of openMenus) {
                    if (o === g) {
                        omRemove = g;
                    }
                }
            }
        }
        // value that should be removed from openMenus
        return omRemove;
    };

    return (
        <li className={`${isArrayNotEmpty(itemChildren) ? "has-children" : ""}`}>
            <div className={isOpen ? (isActive ? "menu-item open active" : "menu-item open") : "menu-item"}>
                {isActive && (
                    <span className="active-icon">
                        <FontAwesomeIcon icon={icons.regular.faArrowRight} />
                    </span>
                )}
                <div className="menu-item-text">
                    <NavLink to={path} end>
                        {nav_title}
                    </NavLink>
                </div>
                <div>
                    {isArrayNotEmpty(itemChildren) && (
                        <button onClick={toggleOpen}>
                            <FontAwesomeIcon icon={icon} />
                        </button>
                    )}
                </div>
            </div>
            <div>{children}</div>
        </li>
    );
};

MenuItem.displayName = "MenuItem";
export { MenuItem };

/**
 * @typedef {object} MenuItemProps
 * @property {string[]} openMenus
 * @property {import('react').Dispatch<import('react').SetStateAction<string[]>>} setOpenMenus
 */
