import { Logger } from 'cms/utils/logger'

/**
 * Validate the given questions
 * @param {FormQuestion[]} requiredQuestions
 */
const validateQuestions = (requiredQuestions) => {
    Logger.info(`[validate-questions] Number of required questions to check: ${requiredQuestions.length}`)

    // check if every required question is valid
    const isFormValid = requiredQuestions.every((question) => {
        const { type, label, isValid, isLogicallyInvalid } = question

        // a question may pass validation rules, but may be logically invalid
        const isQuestionValid = isValid && !isLogicallyInvalid
        Logger.debug(`Question: ${type} ${label} ${isQuestionValid}`)
        return isQuestionValid
    })

    Logger.info(`[validate-questions] The form is valid: ${isFormValid}`, { isFormValid })
    return isFormValid
}

export { validateQuestions }
