import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CarouselImage } from 'cms/components/hero-carousel/carousel-image'
import { RichTextSimple } from 'cms/rich-text/rich-text-simple'
import { regular } from 'icons'
// const { faChevronLeft, faChevronRight, faArrowRight, faArrowLeft } = regular
const { faChevronLeft, faChevronRight } = regular

const HeroCarouselSlide = (props) => {

    const { slides, images, index, total, moveIndex } = props || {}
    const slide = slides[index]
    const slideImage = slide?.image
    const { title, description, link } = slide || {}
    const { text, url: link_url } = link || {}
    const image = Array.isArray(images) && images[index]
    let settings
    if (slideImage) {
        settings = slideImage?.settings
    }
    const previous = () => {
        moveIndex(-1)
    }
    const next = () => {
        moveIndex(1)
    }

    return (
        <div className={`carousel-slide`}>

            <div className="carousel-image-wrapper">
                <CarouselImage
                    image={image}
                    slide={slide}
                    settings={settings}
                    isHomepage={false}
                />
            </div>

            <div className="carousel-content">

                <div className='carousel-slide-title'>{title}</div>

                <RichTextSimple
                    richtext={description}
                    className='carousel-slide-description'
                />

                <div className="controls">

                    <Link to={link_url} className='carousel-slide-link'>
                        {text}
                    </Link>

                    <div className="forward-back">

                        <button aria-label="Previous" onClick={previous}>
                            {/*<FontAwesomeIcon icon={ faArrowLeft } />*/}
                            <FontAwesomeIcon icon={ faChevronLeft } />
                        </button>

                        <span>{`${index + 1}/${total}`}</span>

                        <button aria-label="Next" onClick={next}>
                            {/*<FontAwesomeIcon icon={ faArrowRight }/>*/}
                            <FontAwesomeIcon icon={ faChevronRight }/>
                        </button>

                    </div>
                </div>
            </div>

        </div>
    )
}
export { HeroCarouselSlide }
