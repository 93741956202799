import { useFormState } from 'cms/forms/context'
import { useMemo } from 'react'
import { ErrorMessages } from '../error-messages'
import { Logger } from 'cms/utils/logger'

/**
 * Controls when the given error is updated
 * @template T
 * @param {T} currentError 
 * @param {FormQuestion} question 
 */
export function useShowError (currentError, question) {

    const { guid, isValid, isLogicallyInvalid } = question
    const { validatedQuestions } = useFormState()

    const cache = useMemo(
        () => {
            const shouldShow = validatedQuestions.includes(guid)

            // validation and logical validation are tracked separately so that
            // they may update independently of each other
            const error = isLogicallyInvalid ?
                ErrorMessages.invalid() :
                currentError

            const errorExists = !isValid || isLogicallyInvalid

            Logger.debug(`[show-error] Updating ui for question (${guid}): shouldShow => ${shouldShow}`)

            return {
                error,
                hasError: errorExists && shouldShow
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [validatedQuestions]
    )

    return cache
}
