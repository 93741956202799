import { SimpliCitySDK } from 'cms/sdk'
import { Logger } from 'cms/utils/logger'

/**
 * Detects redirects and sends user to the target path if found.
 * @type {import('react-router-dom').LoaderFunction}
 */
const redirectLoader = async ({ request }) => {
    const url = new URL(request.url)
    const source = url.pathname
    const token = 'UNAVAILABLE'

    Logger.debug(`[redirectLoader] Looking up redirects for ${source}`)
    try {
        /** @type {PaginatedResults<Redirect>} */
        const { pagination, results } = await SimpliCitySDK.redirects.find(token, { source })

        const hasRedirect = pagination.total > 0
        if (!hasRedirect) {
            return null
        }

        const [redirect] = results

        Logger.debug(`[redirectLoader] Redirect found for ${source}, moving to ${redirect?.target}`)

        return new Response(null, {
            status: 301,
            headers: {
                "location": redirect.target
            },
        })
    } catch (error) {
        // console.error('[redirectLoader] failed to lookup redirect for %s', source, error)
        Logger.error(`[redirectLoader] failed to lookup redirect for: ${source}, [${error.message}]`)
        Logger.error(error)
        
        return null
    }
}

export { redirectLoader }
