import { SimpliCitySDK } from "cms/sdk";
import { useEffect, useState } from "react";
import { useAuth } from "cms/auth/auth/hooks/useAuth";


const useFeed = (input) => {
    
    const { term, type, category, categoryName, quantity, range } = input || {}
    const { token } = useAuth();
    const [models, setModels] = useState([]);
    const [pagination, setPagination] = useState();
    const [loaded, setLoaded] = useState(false);
    
    const fetchFeed = async (token, quantity, timer) => {
        const page = 1;
        const pageSize = quantity;
        const options = { page, pageSize, type, term, category, categoryName, range };
        const { pagination, results: models } = await SimpliCitySDK.model.findFeed(token, options);
        setModels(models);
        setPagination(pagination);
        setLoaded(true);
        clearInterval(timer);
    };
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!loaded) {
                setLoaded(true);
            }
        }, 12000);
        fetchFeed(token, quantity, timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, quantity]);
    
    
    return {
        models, pagination, loaded
    }
}

export { useFeed }
