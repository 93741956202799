import React from "react";
import { Text } from "cms/fields/text";
import { Date } from "cms/fields/dates/date";
import { BannerImage } from "cms/shared/images/banner-image";
import { CategoriesList } from "layout/story/categories-list";
import { getFields } from "cms/fields/_helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight } from 'icons'
import { icons } from "icons";

import { useSimpliCityPath } from "cms/webpage";
import { Link } from "react-router-dom";

const ResultItemStory = (props) => {
    const { model } = props;
    const { title, path, description } = model || {};
    const fields = getFields(model);
    const { author, date } = fields || {};
    const { path: activePath } = useSimpliCityPath();
    const hide = !activePath?.startsWith("/dnv-stories");
    // const hide = false

    return (
        <div className="simplicity-story-item">
            {/*  Title / Link  */}
            <div className="simplicity-story-item-title">
                <h3 className="field-content">
                    <Link to={path}>{title}</Link>
                </h3>
            </div>

            {/*  Author / Date  */}
            <div className="views-field views-field-name">
                <h4 className="field-content">
                    <div className="field field-date date">
                        By <Text field={author} /> on <Date field={date} />
                    </div>
                </h4>
            </div>

            {/*  Banner Image  */}
            <div className="views-field views-field-field-banner-image">
                <div className="field-content banner-image-style">
                    <BannerImage model={model} />
                </div>
            </div>

            {/*  Video  */}
            <div className="views-field views-field-field-blog-video">
                <div className="field-content"></div>
            </div>

            {/*  Story Summary  */}
            <div className="views-field views-field-body">
                <div className="field-content">
                    <p>{description}</p>
                </div>
            </div>

            {/*  Link to Story  */}
            <div className="views-field views-field-view-node">
                <span className="field-content field-name-field-tile-link more-link">
                    <Link to={path}>
                        Continue reading {/*  arrow icon  */}
                        <FontAwesomeIcon icon={icons.regular.faArrowRight} />
                    </Link>
                </span>
            </div>

            {/*  Links to Category lists  */}
            {!hide && (
                <div className="field field-text categories">
                    <CategoriesList model={model} />
                </div>
            )}

            {/*  Hard Rule  */}
            <hr className="simplicity-story-hr" style={{}} />
        </div>
    );
};

export { ResultItemStory };
