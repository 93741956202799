import dayjs from "dayjs";
import { CmsConfig } from "cms/config";
// import { getPrimaryDate, hasPrimaryDate } from "cms/models/helpers"

const ResultItem = (props) => {
    const { model } = props;
    const {
        // Id,
        Path,
        Subject,
        StartTime,
        Description,
    } = model;

    const startTime = dayjs.utc(StartTime).format(CmsConfig.dates.display.date_fullday_with_time);

    return (
        <div className="feed-result-row">
            <h3 className="title">
                <a href={Path}>{Subject}</a>
            </h3>
            {startTime && <div className="field field-name-post-date">{startTime}</div>}
            <div className="feed-result-description">
                <p>{Description}</p>
            </div>
        </div>
    );
};

export { ResultItem };
