import React, { useEffect, useState } from 'react'
import { isArrayEmpty } from 'cms/utils/empty-exists'
import { EmptyCarouselSlide } from 'cms/components/hero-carousel/variants/empty-carousel-slide'
import { HeroCarouselSlide } from 'cms/components/hero-carousel/variants/hero-carousel-slide'
import { HeroCarouselSlideHomepage } from 'cms/components/hero-carousel/variants/hero-carousel-slide-homepage'
import { useImageLoader } from 'cms/components/hero-carousel/use-image-map-loader'

const HeroCarousel = (props) => {

    const { component, field } = props
    const { name } = field || {}
    const {
        getImageData,
        preloadImageArray,
        preloadImage,
    } = useImageLoader()
    const { slides = [] } = component || {}

    const [index, setIndex] = useState(0)
    const [images, setImages] = useState(null)

    const isHomepage = name === 'hero'
    const hasContent = !isArrayEmpty(slides)
    const total = slides?.length ?? 0

    const buildSlidesData = async (slides) => {
        if (!slides) {
            return
        }
        const datas = []
        const first_data = await getImageData(slides[0]?.image?.image)
        const first_image = await preloadImage(first_data)
        setImages([first_image])
        try {
            for (const slide of slides) {
                const { image } = slide || {}
                const { image: image_guid } = image || {}
                if (image_guid) {
                    const data = await getImageData(image_guid)
                    datas.push(data)
                }
            }
            const images = await preloadImageArray(datas)
            setImages(images)
        } catch (err) {
            console.error(err)
        }
        return images
    }

    useEffect (() => {
        buildSlidesData(slides)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slides])

    function moveIndex (increment) {
        let idx = index + increment
        if (idx > slides.length - 1) {
            idx = 0
        }
        if (idx < 0) {
            idx = slides.length - 1
        }
        setIndex(idx)
    }

    if (!hasContent) {
        return <EmptyCarouselSlide isHomepage={true} />

    } else {
        return (
            <>
                {!isHomepage &&
                    <HeroCarouselSlide
                        slides={slides}
                        images={images}
                        key={index}
                        index={index}
                        total={total}
                        moveIndex={moveIndex}
                    />
                }
                {isHomepage &&
                    <HeroCarouselSlideHomepage
                        slides={slides}
                        images={images}
                        key={index}
                        index={index}
                        total={total}
                        moveIndex={moveIndex}
                    />
                }
            </>
        )
    }
}

HeroCarousel.displayName = 'HeroCarousel'
export { HeroCarousel }
