import { NewsResultItem } from "layout/homepage/feeds/feed-carousel/news/news-result-item";
import { isArrayNotEmpty } from "cms/utils/empty-exists";

const NewsFeed = (props) => {
    
    let { models } = props;
    if (!isArrayNotEmpty(models)) {
        return (<div className="column" />)
    }
    
    return (
        <>
            {models.map((model, index) => {
                const { guid } = model;
                return <NewsResultItem key={`${guid}${index}`} model={model} />;
            })}
        </>
    );
};

export { NewsFeed };
