import React from 'react'
import { FeedbackHelpful } from 'components/feedback-helpful'
import { CalendarFeedFilter } from 'cms/shared/feeds/calendar'

const Events = () => {
    
    return (
        <main>
            <h1 style={{ marginTop: '16px' }}>Events</h1>
                <>
                    <div>
                        <CalendarFeedFilter />
                    </div>
                </>
            <FeedbackHelpful />
        </main>
    )
}


export { Events }
