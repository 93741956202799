import { useClearRefinements } from "react-instantsearch";
import ReactSelect from "react-select";
import { useDropdownStyles } from "../use-dropdown-styles";
import { RefinementOption } from "./refinement-option";
import { useCurrentRefinementList } from "./use-current-refinement-list";
import { ValueContainerSummary } from "./value-container";
import { Logger } from 'cms/utils/logger'

/**
 * @param {object} props
 * @param {string} props.attribute
 * @param {string} props.placeholder
 */
export function ContentTypeFilter (props) {
    const { attribute, placeholder, className } = props;

    const styles = useDropdownStyles({ size: "lg" });

    const { items, refine, currentItems } = useCurrentRefinementList(attribute);
    const { refine: clear } = useClearRefinements({
        includedAttributes: [attribute],
    });

    return (
        <ReactSelect
            placeholder={placeholder}
            aria-label={placeholder}
            value={currentItems}
            className={className}
            components={{
                Option: RefinementOption,
                ValueContainer: ValueContainerSummary,
            }}
            {...styles}
            /**
             * We ignore the selected type here since it will give us an
             * array when selecting, and a single value when removing.  The
             * action contains the mutated value(s). The algolia `refine`
             * method handles toggling the refinement.
             */
            onChange={(selectedItems, action) => {
                if (action.action === "clear") {
                    // handle a full clear
                    Logger.debug(`[refinement-filter] Clearing refinements for ${attribute}`)
                    clear()
                } else {
                    // handle a single select or remove
                    const item = action.option || action.removedValue;

                    if (item) {
                        Logger.debug(`[refinement-filter] Toggling refinement ${item.value} for attribute: ${attribute}`)
                        refine(item.value)
                    }
                }
            }}
            getOptionLabel={(contentType) => contentType.label}
            getOptionValue={(contentType) => contentType.value}
            isOptionSelected={(contentType) => contentType.isRefined}
            options={items}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isSearchable={false}
            isMulti
            isClearable
        />
    );
}
