import { EventsStyleFeed } from "layout/homepage/feeds/feed-component/event/common/events-style-feed";
import { useFeed } from "layout/homepage/feeds/feed-component/use-feed";
import { useWindowSize } from "layout/homepage/useWindowSize";

const EventFeed = (props) => {
    
    const { category, categoryName } = props;
    const term = undefined;
    const title= "Upcoming events"
    const type = "model.event"
    const links = [{
        text: "All events",
        path: "/events",
    }]
    const quantity = 3
    const range = "future"
    const empty_message = "There are no upcoming events."
    const { isDesktop, isTablet } = useWindowSize();
    const customCssClass = isDesktop || isTablet ? "one-third" : ""
    
    const {models, loaded, pagination} = useFeed(
        { term, type, category, categoryName, quantity, range }
    )
    
    return (
        <>
            <EventsStyleFeed
                type={type}
                title={title}
                models={models}
                pagination={pagination}
                links={links}
                empty_message={empty_message}
                loaded={loaded}
                customCssClass={customCssClass}
            />
        </>
    );
};

export { EventFeed };
