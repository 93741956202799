import { colors } from './log-options'
import Package from '../../../package.json'

export const consoleLog = (log, level) => {
    let mlevel
    if (level === 'error') {
        mlevel = ansiColorWrap(colors.red, `[${log.level}]`)
    } else if (level === 'warn') {
        mlevel = ansiColorWrap(colors.yellow, `[${log.level}]`)
    } else if (level === 'info') {
        mlevel = ansiColorWrap(colors.cyan, `[${log.level}]`)
    } else {
        mlevel = ansiColorWrap(colors.blue, `[${log.level}]`)
    }
    const mdate = ansiColorWrap(colors.blackBright,`[${log.dt.toISOString()}]`)
    const mmessage = `${mdate} ${mlevel} ${log.message}`
    log.location = log.context.url
    log.message = mmessage
    console.log(mmessage, {log})
    return log
}

export const cloudLog = (log, level) => {
    // const mdate = ansiColorWrap(colors.blackBright,`[${log.dt.toISOString()}]`)
    // let mlevel
    let mmessage
    if (level === 'error') {
        // mlevel = ansiColorWrap(colors.red, `[${log.level}]`)
        // mmessage = `${mdate} ${mlevel} ${ansiColorWrap(colors.red,`${log.message}`)}`
        mmessage = `${ansiColorWrap(colors.red,`${log.message}`)}`
    } else if (level === 'warn') {
        // mlevel = ansiColorWrap(colors.yellow, `[${log.level}]`)
        // mmessage = `${mdate} ${mlevel} ${ansiColorWrap(colors.yellow,log.message)}`
        mmessage = `${ansiColorWrap(colors.yellow,log.message)}`
    } else if (level === 'info') {
        // mlevel = ansiColorWrap(colors.cyan, `[${log.level}]`)
        // mmessage = `${mdate} ${mlevel} ${log.message}`
        mmessage = `${log.message}`
    } else {
        // mlevel = ansiColorWrap(colors.blue, `[${log.level}]`)
        // mmessage = `${mdate} ${mlevel} ${log.message}`
        mmessage = `${log.message}`
    }
    if (log && log.context) {
        log.location = log.context.url
    }
    log.message = mmessage
    // console.log(mmessage)
    return log
}

export const ansiColorWrap = (colorCode, message) => {
    const enableWebConsole = process.env.REACT_APP_ENABLE_CONSOLE_LOG === 'true' || false
    if (!enableWebConsole) {
        const open = `\u001b[${colorCode}m`
        const close = `\u001b[0m`
        return `${open}${message}${close}`
    } else {
        return message
    }
}

export const stripAnsiCodes = (message) => {
    // eslint-disable-next-line no-control-regex
    return message.replace(/[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g, '')
}
export const getFilename = (filepath) => {
    return filepath.split(/(\\|\/)/g).pop()
}

const date_formatted = (date) => {
    const dateOptions = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour12: true,
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
    };
    date.toLocaleString('en-US', dateOptions)
    // date.toISOString()
}

export const getVersion = () => {
    const version  = Package.version
    return version
}