import React, { useState } from "react";
import cn from "clsx";
import { NavLink } from "react-router-dom";
import { Menu } from "./menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HoverIntent } from "./hoverIntent";
import { solid } from "icons";
const { faChevronDown, faChevronRight, faArrowRight } = solid;

const MenuItem = (props) => {
    const {
        title = "Unknown",
        url = "/#",
        nav_children = [],
        size,
        side,
        parent_position = 0,
        position,
        level: _level = 0,
    } = props;

    const expand = nav_children.length !== 0;
    const first = position === 0 ? "first" : undefined;
    const last = position === size - 1 ? "last" : undefined;
    const level = Number(_level) + 1;
    const menuId = "menu_" + level + "_" + parent_position + "_" + position;
    const isFirstLevel = level === 1;
    const isSecondLevel = level === 2;
    const isGreaterLevels = level > 1;
    const [showMenu, setShowMenu] = useState("");
    // build classes; the classname utility will remove falsy/empty values
    const classNames = cn(
        "simplicity-menu-item",
        `level${level}`,
        side,
        first,
        last,
        menuId,
        showMenu === menuId ? "is-open" : ""
    );

    return (
        <HoverIntent
            component="li"
            key={menuId}
            enterCallback={() => setShowMenu(menuId)}
            leaveCallback={() => setShowMenu("")}
        >
            <div className={classNames}>
                <NavLink to={url} title={title}>
                    {isFirstLevel && (
                        <div className="menu-item-root-target">
                            <div className="menu-item-root-target-text">{title}</div>
                            <div className="chevron">
                                <FontAwesomeIcon icon={faChevronDown} size="xs" />
                            </div>
                        </div>
                    )}
                    {isGreaterLevels && <div className="menu-item-target">{title}</div>}
                </NavLink>
                {expand ? (
                    <>
                        {isSecondLevel && (
                            <>
                                <button
                                    className="submenu-trigger inactive"
                                    aria-expanded="false"
                                    aria-label="Expand this section of the menu"
                                >
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={side === "left" ? "fa-flip-horizontal" : ""}
                                        size="xs"
                                    />
                                </button>
                                <button
                                    className={`submenu-trigger active ${side}`}
                                    aria-expanded="false"
                                    aria-label="Expand this section of the menu"
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className={side === "left" ? "fa-flip-horizontal" : ""}
                                        size="xs"
                                    />
                                </button>
                            </>
                        )}
                        <Menu side={side} level={level}>
                            {nav_children.map((child, index) => {
                                const size = nav_children?.length;
                                const { title, url, children } = child;
                                return (
                                    <MenuItem
                                        key={`${index}${position}${url}`}
                                        parent_position={position}
                                        position={index}
                                        level={level}
                                        side={side}
                                        size={size}
                                        title={title}
                                        url={url}
                                        nav_children={children}
                                    />
                                );
                            })}
                        </Menu>
                    </>
                ) : (
                    <div className={"no-button-spacer"}></div>
                )}
            </div>
        </HoverIntent>
    );
};

MenuItem.displayName = "MenuItem";
export { MenuItem };
