import {
    handleJSON,
    makeRequest,
    parseParams,
} from './common'
import {
    getAlert,
    getCurrentAlert,
} from "./alert"
import {
    getApplication,
} from "./application"
import {
    generateAttachmentSasTokenPublic,
    createAttachmentPublic,
    deleteAttachmentPublic,
} from './attachment'
import {
    getDocuments,
    getDocument,
    getDocumentByFilePath,
} from "./document"
import {
    getExcerpts,
    getExcerpt,
} from "./excerpt"
import {
    getForm,
    getForms,
} from "./form"
import {
    createFormResponse,
} from './form-response'
import {
    getFormSetting,
    getFormSettings,
} from "./form-settings"
import {
    getImage,
    getImageByFilePath,
    getImages,
} from './image'
import {
    getLayout,
    getLayouts,
    getLayoutsForModel,
} from './layout'
import {
    getLink,
} from "./link"
import {
    getModels,
    getModel,
    getRecentModelsFeed,
    getRecentEventModelsFeed,
    getModelTOC,
} from './model'
import {
    getModelFeatures,
    getModelFeaturesByModel,
    getModelFeature,
    getModelFeatureDefaultsByModelType,
} from './model_feature'
import {
    getOrganization,
} from "./organization"
import {
    getOrgSettings,
    getOrgSetting,
} from './organization-settings'
import {
    getRedirect,
    getRedirects,
} from "./redirects"
import {
    getSchemaModels,
    getSchemaModel,
    getSchemaModelByType,
} from "./schema-model"
import {
    getSeo,
    getSeoByGuid,
    getSeoByWebpageGuid,
} from "./seo"
import {
    getWebpage,
    getWebpageByPath,
    getWebpageBySlug,
    getWebpageChildren,
    getWebpageParents,
    getWebpages,
    getWebpageTree,
    getWebpageKeywords,
} from './webpage'
import {
    getWebsite,
    getWebsites,
    getWebsiteForApplication,
    getWebsitesForOrganization,
    getWebsiteForDomain,
} from './website'

// end imports
// ====================================================================================== //

// start exports

const SimpliCitySDK = {
    check: function () {
        console.log("-----------------------------------------------------")
        console.log("      SimpliCitySDK (Public) located!")
        console.log("-----------------------------------------------------")
        return true
    },
    alert: {
        findOne: getAlert,
        findCurrent: getCurrentAlert,
    },
    application: {
        findOne: getApplication,
    },
    attachment: {
        getToken: generateAttachmentSasTokenPublic,
        // findOne: getAttachment,
        // findOneByFilePath: getAttachmentByFilePath,
        create: createAttachmentPublic,
        delete: deleteAttachmentPublic,
    },
    documents: {
        find: getDocuments,
        findOne: getDocument,
        findOneByFilePath: getDocumentByFilePath,
    },
    excerpt: {
        find: getExcerpts,
        findOne: getExcerpt,
    },
    form: {
        findOne: getForm,
        find: getForms,
    },
    formResponse: {
        create: createFormResponse,
    },
    formSettings: {
        findOne: getFormSetting,
        find: getFormSettings,
    },
    images: {
        find: getImages,
        findOne: getImage,
        findOneByFilePath: getImageByFilePath,
    },
    layout: {
        find: getLayouts,
        findForModel: getLayoutsForModel,
        findOne: getLayout,
    },
    link: {
        findOne: getLink,
    },
    model: {
        find: getModels,
        findOne: getModel,
        findFeed: getRecentModelsFeed,
        findEventFeed: getRecentEventModelsFeed,
        toc: getModelTOC,
    },
    modelFeatures: {
        find: getModelFeatures,
        findByModel: getModelFeaturesByModel,
        findByModelFeature: getModelFeature,
        findDefaults: getModelFeatureDefaultsByModelType,
    },
    organization: {
        findOne: getOrganization,
    },
    // Review: is public
    orgSettings: {
        find: getOrgSettings,
        findOne: getOrgSetting,
    },
    redirects: {
        find: getRedirects,
        findOne: getRedirect,
    },
    schemaModel: {
        find: getSchemaModels,
        findOne: getSchemaModel,
        findOneByType: getSchemaModelByType,
    },
    seo: {
        find: getSeo,
        findOne: getSeoByGuid,
        findOneByWebpage: getSeoByWebpageGuid,
    },
    utils: {
        makeRequest,
        parseParams,
        handleJSON,
    },
    webpage: {
        find: getWebpages,
        findOne: getWebpage,
        findOneBySlug: getWebpageBySlug,
        findOneByPath: getWebpageByPath,
        findTree: getWebpageTree,
        findParents: getWebpageParents,
        findChildren: getWebpageChildren,
        getKeywords: getWebpageKeywords,
    },
    website: {
        find: getWebsites,
        findOne: getWebsite,
        findForOrganization: getWebsitesForOrganization,
        findForApplication: getWebsiteForApplication,
        findForDomain: getWebsiteForDomain,
    },
    
}

export default SimpliCitySDK