import { icons } from "icons";
import { FeedPaginationItemSm } from "layout/homepage/feeds/limited-pagination/limited-pagination-item";
/**
 * @type {import('react').FC<FeedPaginationProps>}
 */
const LimitedFeedPagination = (props) => {

    const { pagination } = props;
    const { next, prev } = pagination.controls;
    const { hasNext, hasPrev, page: currentPage, numPages, hasResults } = pagination.state;

    // hide pagination if there are no results
    if (!hasResults) {
        return null;
    }

    return (
        <div className="feed-pagination-wrapper">
            <div className="feed-pagination-container">
                <FeedPaginationItemSm
                    isDisabled={!hasPrev}
                    onClick={prev}
                    iconLeft={icons.regular.faChevronLeft}
                />

                {currentPage} of {numPages}
                <FeedPaginationItemSm
                    isDisabled={!hasNext}
                    onClick={next}
                    iconRight={icons.regular.faChevronRight}
                />

            </div>
        </div>
    );
};

export { LimitedFeedPagination };

/**
 * @typedef {ReturnType<import('../hooks/use-fetch-feed')['useFetchFeed']>['pagination']} Pagination
 */

/**
 * @typedef {object} FeedPaginationProps
 * @property {Pagination} pagination
 */



// const isDesktop = useMedia("screen and (min-width: 600px)");
// /**
//  * Nulls represent where we should render an ellipsis
//  */
// const pages = useMemo(() => {
//     // window size for desktop and mobile
//     const window = isDesktop ? 4 : 3;
//
//     // we have less pages than the window, just return that sequence
//     if (numPages <= window) {
//         const seq = sequence(1, numPages);
//         return seq;
//     }
//
//     /**
//      * @type {Array<number | null>}
//      */
//     const pageNums = [1];
//
//     // offset the sequence length by 1 since we're adding the last page
//     const sequenceLength = window - 1;
//
//     if (currentPage <= window) {
//         // current page is near the start
//         const seq = sequence(2, sequenceLength);
//         pageNums.push(...seq, null);
//     } else if (numPages - currentPage + 1 <= window) {
//         // current page is near the end
//         const seq = sequence(numPages - window + 1, sequenceLength);
//         pageNums.push(null, ...seq);
//     } else {
//         // current page is in the middle
//         const seq = sequence(currentPage - 1, sequenceLength);
//         pageNums.push(null, ...seq, null);
//     }
//
//     // add the last page
//     pageNums.push(numPages);
//
//     return pageNums;
// }, [currentPage, isDesktop, numPages, pageLimit]);
