import { categories } from "pages/story/category/story-config";
import { useSimpliCityPath } from "cms/webpage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StoryNav = (props) => {
    const { title } = props;
    const { slug } = useSimpliCityPath();

    return (
        <div className="side-nav">
            <div
                id="edit-field-blog-category-tid-wrapper"
                className="views-exposed-widget views-widget-filter-field_blog_category_tid"
            >
                <h2 className="story-category-title">{title}</h2>
                <div className="title-underline"></div>
                <div className="story-categories">
                    <div className="form-item form-type-select form-item-field-blog-category-tid">
                        <div className="bef-select-as-links jquery-once-2-processed">
                            <div className="form-item">
                                {categories.map((category) => {
                                    const cat_slug = category.slug;
                                    const isCurrent = cat_slug === slug;
                                    const active = isCurrent ? "active_category active" : "";

                                    return (
                                        <div className={`story-category ${active}`} key={category.slug}>
                                            <a href={category.path}>
                                                <span className="">{category.title}</span>
                                                <FontAwesomeIcon icon={`fa-solid fa-chevron-right`} />
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { StoryNav };
