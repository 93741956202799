
import  {
    faCaretUp,
} from '@awesome.me/kit-5f107d6f8b/icons/sharp/solid'

const sharpSolid = {
    faCaretUp,
}

export {
    sharpSolid,
}
