
/**
 * Initialize rules list with constant rules
 * @param {FormQuestion[]} questions
 * @param {DependencyPairs} deps 
 * @param {boolean} hideByDefault 
 */
const initLogicRules = (questions, deps, hideByDefault) => {

    /** @type {LogicRule[]} */
    const rules = []

    if (hideByDefault) {
        rules.push({
            target: '*',
            action: 'HIDE_TARGET_QUESTION',
            paths: []
        })
    }

    for (const question of questions) {
        const isDependent = deps.has(question.guid)
        const hasDependentGroup = deps.has(question.group)

        // determine if a question is shown/hidden
        // this works in conjunction with the rule specified above
        if (!isDependent && !hasDependentGroup) {
            rules.push({
                target: question.guid,
                action: 'SHOW_TARGET_QUESTION',
                paths: []
            })
        }
    }

    return rules
}

export { initLogicRules }
