import { SimpliCitySDK } from 'cms/sdk'
import { uploadFile } from 'cms/integration/azure/storage/azure-storage'
import { FILE_TYPES_BY_EXTENSION } from 'cms/forms/data/file-types'
import { generateGuid } from 'cms/utils/empty-exists'
import { Logger } from 'cms/utils/logger'

/**
 * Replaces characters in the file name.
 * @param {File} file
 */
export const getAcceptableFilename = (file) => {
    let fileName = file?.name?.replace(/ /g,'_')
    fileName = `${generateGuid()}_${fileName}`
    Logger.debug(`renaming file from [${file.name}] to [${fileName}]`)
    return fileName
}
/**
 * Upload the given files.
 * @param {File[]} acceptedFiles
 * @param {string} formId
 * @param {string} organization
 */
export const processFiles = async (acceptedFiles, formId, organization) => {
    
    /**
     * @type {Attachment[]}
     */
    const uploaded_attachments = []
    
    for (const file of acceptedFiles) {
        
        Logger.debug(`start upload file ${file.name}`)
        Logger.debug(`get sas token`)
        const sas_response = await SimpliCitySDK.attachment.getToken()
        const {
            sas,
            storageAccountName,
            storageAccount,
            containerName,
            // fileUrlBase
        } = sas_response
        // Logger.debug('SAS TOKEN RESPONSE', sas_response)
        
        // *** UPLOAD TO AZURE STORAGE ***
        Logger.debug(`Uploading file: ${file.name}`)
        // setUploadStatus({ status: 'INCOMPLETE', message: 'The upload has begun.' })
        const uploadResult = await uploadFile(storageAccount, containerName, file, sas)
        // setUploadStatus(uploadResult)
        if (uploadResult.status === 'ERROR') {
            Logger.debug(`Failed - Uploading to storage for file: ${file.name}`)
            Logger.error(uploadResult)
            throw Error(uploadResult.message)
        } else {
            Logger.debug(`Succeeded - Uploading to storage for file: ${file.name}`)
            const encodedFilename = encodeURIComponent(file.name)
            const storage_url = `${storageAccount}/${containerName}/${encodedFilename}`
            // const public_url = `${fileUrlBase}/${encodedFilename}`
            const ext = file.name.split('.').pop()
            const payload = {
                title: `Attachment - ${file.name}`,
                reference: formId,
                // TODO : figure out a good description at some point (purpose, or describe why it was uploaded?)
                // description: `Untitled Image - ${file.name}`,
                // url: public_url,
                file_name: file.name,
                file_ext: ext,
                file_type: file.type,
                file_size: file.size,
                storage_url: storage_url,
                storage_account: storageAccountName,
                storage_container: containerName,
                storage_filepath: encodedFilename,
            }
            
            // Add record to the database
            Logger.debug(`Calling API for file: ${file.name}`)
            const response = await SimpliCitySDK.attachment.create(
                organization,
                formId, // TODO: will deprecate in favour of reference
                payload
            )
            Logger.debug(response)
            uploaded_attachments.push(response)
        }
    }
    return uploaded_attachments
}

export const getAcceptableFiles = (isLimited, acceptableExtensions) => {
    // default => does not limit types
    let acceptableTypes = undefined
    if (isLimited) {
        acceptableTypes = filterAcceptableFiles(acceptableExtensions)
    }
    return acceptableTypes
}

export const filterAcceptableFiles = (fileTypes) => {
    let acceptedTypes = {}
    fileTypes.map((fileExtension) => (
        acceptedTypes = {
            ...acceptedTypes,
            ...FILE_TYPES_BY_EXTENSION[fileExtension]
        }
    ))
    return acceptedTypes
}
