import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { NavigationListStyle } from './navigation-list-style'
import { SimpleListStyle } from './simple-list-style'
import { useFetchNavigationList } from './use-fetch-navigation-list'
import { Logger } from 'cms/utils/logger'

const NavigationListComponent = (props) => {

    const { component } = props
    const { description } = component || {}

    const { children, error } =  useFetchNavigationList()
    if (error) {
        Logger.error(error)
    }
    const { webpage, website } = useSimpliCity()

    // if the parent is home - then we are at the section level
    let isSection = webpage?.parent === website?.home

    return (
        <div className='navigation-list'>
            <div className='navigation-list-body'>

                {!isSection &&
                    <SimpleListStyle description={description}>
                        {children}
                    </SimpleListStyle>
                }

                {isSection &&
                    <NavigationListStyle>
                        {children}
                    </NavigationListStyle>
                }

            </div>
        </div>
    )
}
NavigationListComponent.displayName = 'NavigationListComponent'
export { NavigationListComponent }
