import { NewsFeedCarousel } from "layout/homepage/feeds/feed-carousel/news";
import { StoryFeedCarousel } from "layout/homepage/feeds/feed-carousel/story";
import { NoticeFeed } from "./feed-component/notice";
import { EventFeed } from "./feed-component/event/event";
import { MeetingsFeed } from "./feed-component/event/meeting";

const Home_Feeds = () => {
    

    return (
        <div id="feed-home-container" className="feed-home-container">
            
            <div id="feed-home-row" className="feed-home-row news">
                
                <NoticeFeed />
                
                <NewsFeedCarousel />
                
            </div>
            
            <div id="feed-home-row-2" className="feed-home-row">
                
                <EventFeed />

                <MeetingsFeed />
                
            </div>
            
            <StoryFeedCarousel />
            
        </div>
    );
};
Home_Feeds.displayName = "Home_Feeds";
export { Home_Feeds };
