'use strict'

import { Logtail } from '@logtail/browser'
import { Config } from '../../config'
import { logOptions, colors } from '../log/log-options'
import { cloudLog, ansiColorWrap, getVersion } from '../log/log-helpers'

const source_token = Config.Logging.SourceToken
const enableConsole = Config.Logging.enableConsole
const disableCloud = Config.Logging.disableCloud
if (enableConsole) {
    logOptions.sendLogsToConsoleOutput = true
}
if (disableCloud) {
    logOptions.sendLogsToBetterStack = false
}

const LogTailLogger = new Logtail(source_token, logOptions)
let Logger = LogTailLogger
const version = getVersion()

const developerLog = (log) => {
    
    if (log.level === 'error') {
        const error = log.error
        const { message, stack } = error || {}
        const error_message = `[SimpliCityAdminSDK [${version}]] ${log.message} - ${message} \n${stack}`
        log.message = error_message
        log = cloudLog(log, log.level)
    }
    else if (log.level === 'warn') {
        const message = `[SimpliCityAdminSDK [${version}]] ${log.message}`
        log.message = message
        log = cloudLog(log, log.level)
    }
    else {
        const message = `[SimpliCityAdminSDK [${version}]] ${log.message}`
        log.message = message
    }
    return log
}
Logger.use(developerLog)

// Ansi Color wrapping is disabled when the console is enabled
export const black = (message) => ansiColorWrap(colors.black, message)
export const red = (message) => ansiColorWrap(colors.red, message)
export const green = (message) => ansiColorWrap(colors.green, message)
export const yellow = (message) => ansiColorWrap(colors.yellow, message)
export const blue = (message) => ansiColorWrap(colors.blue, message)
export const magenta = (message) => ansiColorWrap(colors.magenta, message)
export const cyan = (message) => ansiColorWrap(colors.cyan, message)
export const white = (message) => ansiColorWrap(colors.white, message)
export const gray = (message) => ansiColorWrap(colors.gray, message)
export const grey = (message) => ansiColorWrap(colors.grey, message)
export const blackBright = (message) => ansiColorWrap(colors.blackBright, message)
export const redBright = (message) => ansiColorWrap(colors.redBright, message)
export const greenBright = (message) => ansiColorWrap(colors.greenBright, message)
export const yellowBright = (message) => ansiColorWrap(colors.yellowBright, message)
export const blueBright = (message) => ansiColorWrap(colors.blueBright, message)
export const magentaBright = (message) => ansiColorWrap(colors.magentaBright, message)
export const cyanBright = (message) => ansiColorWrap(colors.cyanBright, message)
export const whiteBright = (message) => ansiColorWrap(colors.whiteBright, message)

export { Logger }

