import React, { Fragment } from 'react'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { Component } from 'cms/fields/component'
import { EmptyCarouselSlide } from 'cms/components/hero-carousel/variants/empty-carousel-slide'

const HomeHeroCarousel = () => {
    
    const { webpage, model, fields } = useSimpliCity()
    const { hero } = fields || {}
    
    if (!hero) {
        return (
            <EmptyCarouselSlide />
        )
    }
    
    return (
        <>
        {/*<div className='home-banner'>*/}
            {/* Selectively showing just the field called "hero" of type "field.component" */}
            {hero &&
                <Fragment>
                    <Component
                        field={hero}
                        model={model}
                        webpage={webpage}
                    />
                </Fragment>
            }
        {/*</div>*/}
        </>
    )
}

export { HomeHeroCarousel }
