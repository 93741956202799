import React, { useCallback, useRef, useEffect } from "react";

// https://codesandbox.io/p/sandbox/react-hover-intent-lkfkq?file=%2Fsrc%2FApp.tsx%3A1%2C13-1%2C27
// export type FCProps<Props> = Props extends { children: any }
//   ? Props
//   : Props & { children?: ReactNode };

// interface OwnProps {
//   enterCallback: () => unknown;
//   leaveCallback: () => unknown;
//   enterWait?: number;
//   leaveWait?: number;
//   component?: ReactNode;
// }

// type Props = FCProps<OwnProps>;

export function HoverIntent(props) {
    const enterWait = props.enterWait === undefined ? 300 : props.enterWait;
    const leaveWait = props.leaveWait === undefined ? 300 : props.leaveWait;
    const ComponentProp = props.component || "div";
    const isEnterEnvoked = useRef(false);
    const enterTimer = useRef(0);
    const leaveTimer = useRef(0);

    const cancelEnterTimer = useCallback(() => {
        clearTimeout(enterTimer.current);
    }, [enterTimer]);

    const cancelLeaveTimer = useCallback(() => {
        clearTimeout(leaveTimer.current);
    }, [leaveTimer]);

    const handleMouseEnter = useCallback(() => {
        cancelLeaveTimer();
        enterTimer.current = setTimeout(() => {
            props.enterCallback();
            isEnterEnvoked.current = true;
        }, enterWait);
    }, [cancelLeaveTimer, props, enterWait]);

    const handelMouseLeave = useCallback(() => {
        cancelEnterTimer();
        leaveTimer.current = setTimeout(() => {
            if (isEnterEnvoked.current) {
                props.leaveCallback();
                isEnterEnvoked.current = false;
            }
        }, leaveWait);
    }, [cancelEnterTimer, props, leaveWait]);

    useEffect(() => {
        return () => {
            cancelEnterTimer();
            cancelLeaveTimer();
        };
    }, [cancelEnterTimer, cancelLeaveTimer]);

    return (
        <ComponentProp onMouseEnter={handleMouseEnter} onMouseLeave={handelMouseLeave}>
            {props.children}
        </ComponentProp>
    );
}
