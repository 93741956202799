// TODO: NOTE: To swap kits - change both
//  - and make sure the correct fontawesome auth token is available in env vars (.env.yarn)
//  - change the package dependency to the correct kit
//  - and the correct version of that kit (not the same between kits)
//  - and use the matching kit import here
//
//  "@awesome.me/kit-5f107d6f8b": "^1.0.49",
//  "@awesome.me/kit-f6a7b16729": "^1.0.46",
//  "@awesome.me/kit-db56931b65": "^1.0.112",
// --------------------------------------------------------------------------------------------------

// import * as SimplicityIcons from './icons-fontawesome-simplicity'
// import { brands, solid, regular, all } from './icons-fontawesome-simplicity'
// const icons = SimplicityIcons

import * as DNVIcons from './icons-fontawesome-dnv'
import { brands, solid, regular, sharpRegular, sharpSolid, all } from './icons-fontawesome-dnv'
const icons = DNVIcons

const { getIcon } = icons

export { icons, getIcon, brands, solid, regular, sharpSolid, sharpRegular, all }
