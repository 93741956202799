import { ResultItem } from 'cms/shared/feeds/large/result-item'
import { ResultItemBylaw } from 'cms/shared/feeds/large/types/result-item-bylaw'
import { ResultItemAlert } from 'cms/shared/feeds/large/types/result-item-alert'
import { ResultItemGeneric } from 'cms/shared/feeds/large/types/result-item-generic'
import { ResultItemStory } from 'cms/shared/feeds/large/types/result-item-story'
import { SearchFilterBar } from 'cms/shared/feeds/search-filter-bar'
import { FeedPagination } from 'cms/shared/feeds/pagination/feed-pagination'
import { useCategoryInfo } from 'cms/hooks/use-category-info'
import { useFetchFeed } from 'cms/shared/feeds/hooks/use-fetch-feed'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import dayjs from 'dayjs'

const LargeFeedFilter = (props) => {

    const {
        type,
        selected_category,
        enableBar,
    } = props
    const { categories, categoryName, filterName } = useCategoryInfo(type)

    const {
        loading,
        value,
        pagination,
        search
    } = useFetchFeed(type, categoryName, selected_category )

    const {
        first,
        last,
        hasResults,
        total,
    } = pagination.state

    const { models } = value || {}
    const compare = ( a, b ) => {
        const aDate = dayjs(a.updated_at)
        const bDate = dayjs(b.updated_at)
        if ( aDate.isAfter(bDate) ) {
            return -1
        }
        if ( aDate.isBefore(bDate) ) {
            return 1
        }
        return 0
    }
    if (isArrayNotEmpty(models) && type === 'model.alert' ) {
        models.sort(compare)
    }

    return (
        <>
            {enableBar &&
                <SearchFilterBar
                    type={type}
                    categories={categories}
                    filterName={filterName}
                    runSearch={search}
                />
            }
            <div className="template-wrap">
                <div>
                    {enableBar && !loading && hasResults &&
                        <p>{first} to {last} of {total} results.</p>
                    }
                    {enableBar && !loading && !hasResults &&
                        <p>{total} results.</p>
                    }
                    {enableBar && loading &&
                        <p>Loading</p>
                    }

                    {models && models.map((model, index) => {
                        const { type, guid } = model
                        if (type === 'model.bylaw') {
                            return (
                                <ResultItemBylaw
                                    key={`${guid}${index}`}
                                    model={model}
                                />
                            )
                        } else if (type === 'model.alert') {
                            return (
                                <ResultItemAlert
                                    key={`${guid}${index}`}
                                    model={model}
                                />
                            )
                        } else if (type === 'model.story') {
                            return (
                                <ResultItemStory
                                    key={`${guid}${index}`}
                                    model={model}
                                />
                            )
                        } else if (type === 'model.permit' || type === 'model.development-application') {
                            return (
                                <ResultItemGeneric
                                    key={`${guid}${index}`}
                                    model={model}
                                />
                            )
                        } else {
                            return (
                                <ResultItem
                                    key={`${guid}${index}`}
                                    model={model}
                                />
                            )
                        }
                    })}

                    <FeedPagination
                        pagination={pagination}
                    />

                </div>
            </div>
        </>
    )
}

export { LargeFeedFilter }
