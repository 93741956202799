import dayjs from 'dayjs'
import { CmsConfig } from 'cms/config'

const ResultItemAlert = (props) => {

    const { model } = props
    const {
        title,
        description,
        path
    } = model
    const { updated_at: date } = model
    const primary_date = date ? dayjs(date).format(CmsConfig.dates.display.date_fullday) : undefined

    return (
        <div className="feed-result-row">
            <h3 className="title">
                <a href={path}>
                    {title}
                </a>
            </h3>
            {primary_date &&
                <div className="field field-name-post-date">
                    {primary_date}
                </div>
            }
            <div className="feed-result-description">
                <p>{description}</p>
            </div>
        </div>
    )
}

export { ResultItemAlert }
