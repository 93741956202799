import { HomeNoticeResultItem } from "./notice-result-item";
import { SkipDuringPrerender } from "cms/shared/hyrdation/avoid-prerender";
import { CircularProgress } from "@mui/material";

const FeedStyleHeader = (props) => {
    const {
        title,
        models,
        links = [],
        // pagination,
        empty_message,
        loaded,
        customCssClass
    } = props;
    // const { total } = pagination || {};
    const hasModels = !!models && models.length > 0;

    return (
        <div className={`feed-component-container-notices ${customCssClass}`}>
            <div className="feed-component-header">
                <div className="feed-component-title-wrapper">
                    <h2 className="feed-component-title-link">{title}</h2>
                    <div className="feed-component-title-underline"></div>
                </div>
                <div className="feed-component-all-links">
                    {links && links.map((link, index) => (
                        <div key={index} className="feed-component-link-item">
                            <a href={link.path}>{link.text}</a>
                        </div>
                    ))}
                </div>
            </div>
            <div className="feed-component-panel">
                <SkipDuringPrerender>
                    {!loaded && (
                        <div className="spinner-container">
                            <CircularProgress />
                        </div>
                    )}
                    {loaded && !hasModels && <>{empty_message}</>}
                    {models &&
                        models.map((model, index) => {
                            const { guid } = model;
                            return <HomeNoticeResultItem key={`${guid}${index}`} model={model} />;
                        })}
                </SkipDuringPrerender>
            </div>
        </div>
    );
};

export { FeedStyleHeader };
