import { Logger } from "cms/utils/logger";
import { SimpliCitySDK } from "cms/sdk";
import { useEffect } from "react";
import { useAuth } from "cms/auth/auth/hooks/useAuth";


const useFetchWebsite = (props) => {

    const {
        setError,
        setWebsite,
        setSettings,
    } = props

    const { token } = useAuth()

    async function fetchWebsiteData (token) {
        try {

            const domain = window.location.host
            // const domain = 'local.simplicitycms.ca:3300'
            // console.debug(`Using domain: ${domain}`)
            Logger.debug(`Using domain: ${domain}`)
            const website = await SimpliCitySDK.website.findForDomain(token, encodeURIComponent(domain))

            const { organization } = website
            const settings = await SimpliCitySDK.orgSettings.find(token, organization)

            // FIXME: website must come last here in order to provide settings
            setSettings(settings)
            setWebsite(website)

        } catch (error) {
            console.error('Failed to load website data.', error)
            Logger.error('Failed to load website data.', error)
            setError({ message: 'Failed to load website data.', error: error })
        }
    }
    useEffect(() => {
        fetchWebsiteData(token)
        // eslint-disable-next-line
    }, [token])
}

export { useFetchWebsite }
