import { FeedStyleHeader } from "layout/homepage/feeds/feed-component/notice/style-header";
import { useFeed } from "layout/homepage/feeds/feed-component/use-feed";
import { useWindowSize } from "layout/homepage/useWindowSize";

const NoticeFeed = (props) => {
    const {
        category,
        categoryName,
        range,
    } = props;
    const term = undefined;
    
    const title = "Advisory"
    const type = "model.notice"
    const links = [{text: "All advisories", path: "/notices"}]
    const quantity = 1
    const empty_message = "There are currently no notices."
    const { isDesktop, isTablet } = useWindowSize();
    const customCssClass = isDesktop || isTablet ? "one-third" : ""
    
    const {models, loaded, pagination} = useFeed(
        { term, type, category, categoryName, quantity, range }
    )
    
    return (
        <>
            <FeedStyleHeader
                title={title}
                // count={count}
                models={models}
                pagination={pagination}
                links={links}
                empty_message={empty_message}
                loaded={loaded}
                customCssClass={customCssClass}
            />
        </>
    );
};

export { NoticeFeed };
