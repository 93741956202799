import React, { useEffect, useState, Fragment } from "react";
import { SimpliCitySDK } from "cms/sdk";
import { useAuth } from "cms/auth/auth/hooks/useAuth";
import { objectHasPropertyString } from "cms/utils/empty-exists";
import { Logger } from "cms/utils/logger";

const ModelImage = (props) => {
    const { model } = props || {};
    const { token } = useAuth();
    const [image, setImage] = useState();
    const { url, alt } = image || {};

    const image_style = {
        width: "100%",
        height: "200px",
        // height: '100%',
        backgroundImage: `url(${url})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };

    useEffect(() => {
        const fetchImage = async (model) => {
            // only load if the guids don't match
            const guid = model?.banner_image?.image;
            if (!guid) {
                setImage(undefined);
            }
            if (guid && image?.guid !== guid) {
                let current_image = await SimpliCitySDK.images.findOne(token, guid);
                if (current_image && current_image.guid) {
                    setImage(current_image);
                    // loadImageDimensions(current_image.url)
                } else {
                    Logger.warn("Could not load Field Image...", guid);
                }
            }
        };
        fetchImage(model);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model]);

    return (
        <Fragment>
            {objectHasPropertyString(image, "url") && (
                <>
                    <div className={`model-image`} style={image_style} title={alt} />
                    {/*<img*/}
                    {/*    src={url}*/}
                    {/*    alt={alt}*/}
                    {/*/>*/}
                </>
            )}
        </Fragment>
    );
};

export { ModelImage };
