import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { Component } from 'cms/fields/component'
import React, { Fragment } from 'react'

const HomeHighlightArea = () => {
    
    const { webpage, model, fields } = useSimpliCity()
    const { highlights } = fields || {}
    
    return (
        <Fragment>
            {/* Selectively showing just the field called "highlight" of type "field.component" */}
            {highlights &&
                <Fragment>
                    
                    <div className="feed-component-header">
                        <div className="feed-component-title-wrapper">
                            <h2 className="feed-component-title-link">
                                Tell us what you think
                            </h2>
                            <div className="feed-component-title-underline"></div>
                        </div>
                        <div className="feed-component-all-links"></div>
                    </div>
                    
                    <div className='homepage-highlights'>
                        <Component
                            field={highlights}
                            model={model}
                            webpage={webpage}
                        />
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}
HomeHighlightArea.displayName = 'HomeHighlightArea'
export { HomeHighlightArea }
