import { useEffect, useRef} from "react";
import { useLocation } from "react-router-dom";

const useScrollToLocation = () => {
    console.log("scroll to location!");
    const scrolledRef = useRef(false);
    const { hash } = useLocation();
    useEffect(() => {
        if (hash && !scrolledRef.current) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView( { behavior: 'smooth' });
                scrolledRef.current = true;
            }
        }
    });
};

export { useScrollToLocation }
