import { StoryResultItem } from "layout/homepage/feeds/feed-carousel/story/story-result-item";
import { isArrayNotEmpty } from "cms/utils/empty-exists";

const StoriesFeed = (props) => {
    
    let { models } = props;
    if (!isArrayNotEmpty(models)) {
        return null
    }
    
    return (
        <>
            {models.map((model, index) => {
                const { guid } = model;
                return <StoryResultItem key={`${guid}${index}`} model={model} />;
            })}
        </>
    );
};

export { StoriesFeed };
