import { useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { Logger } from 'cms/utils/logger'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'

/**
 * @param webpage
 * @param model
 */
export function useSeo (webpage, model ) {
    
    const { token } = useAuth()
    const [seo,setSeo] = useState()
    const { guid: webpage_guid } = webpage || {}
    const hasSEO = !!seo
    
    const fetchSEO = (guid) => {
        if (!webpage_guid) {
            return
        }
        if (hasSEO) {
            return
        }
        const result = SimpliCitySDK.seo.findOneByWebpage(token, guid)
        .then((result) => {
            setSeo(result)
        })
        .catch((error) => {
            Logger.error(error)
        })
        return result
    }
    
    const getImageData = (image) => {
        if (!image) {
            return
        }
        return image
        // const { image: guid } = image || {}
        // return { guid }
    }
    
    // const fetchImage = async (guid) => {
    //     // only load if the guids don't match
    //     // const guid = model?.banner_image?.image
    //     let image
    //     if (!guid) {
    //         // console.info(`No Banner image ${JSON.stringify(model?.banner_image)}`)
    //         image = undefined
    //     }
    //     Logger.info(`Loading SEO image ${guid}`)
    //
    //     let current_image = await SimpliCitySDK.images.findOne(token, guid)
    //
    //     if (current_image && current_image.guid) {
    //         image(current_image)
    //         // setImageSrc(current_image?.url)
    //         // Logger.info(`Loaded Banner image Metadata url ${current_image.url}`)
    //         // console.info(`Loaded Banner image Metadata url ${current_image.url}`)
    //
    //     } else {
    //         // Logger.warn('Could not load Field Image...', guid)
    //     }
    // }
    // fetchImage(model)
    //
    
    const hasModel = !!model
    if (!hasModel) {
        return {seo}
    }
    fetchSEO(webpage_guid)
    
    const url = new URL(webpage?.path, window.location).href
    const useContentTitle = seo?.use_content_title ?? true
    const useContentDescription = seo?.use_content_description ?? true
    const useContentImage = seo?.use_content_image ?? true
    const useMainTitleForOG = seo?.og_use_main_title ?? true
    const useMainDescriptionForOG = seo?.og_use_main_description ?? true
    const useMainImageForOG = seo?.og_use_main_image ?? true
    const useMainTitleForTW = seo?.tw_use_main_title ?? true
    const useMainDescriptionForTW = seo?.tw_use_main_description ?? true
    const useMainImageForTW = seo?.tw_use_main_image ?? true
    
    const evaluateFlags = (bool, m, s) => {
        const result = bool ? m : s
        if (!!result) {
            return m
        }
    }
    
    const title = evaluateFlags(useContentTitle, model?.title, seo?.title)
    const description = evaluateFlags(useContentDescription, model?.description, seo?.description)
    const image = evaluateFlags(useContentImage, getImageData(model?.banner_image), getImageData(seo?.image))
    
    const og_type = seo?.og_type ?? 'website'
    const og_title = evaluateFlags(useMainTitleForOG, model?.title, seo?.og_title)
    const og_description = evaluateFlags(useMainDescriptionForOG, model?.description, seo?.og_description)
    const og_image = evaluateFlags(useMainImageForOG, getImageData(model?.banner_image), getImageData(seo?.image))
    
    const tw_card_type = seo?.tw_card_type ?? 'summary_large_image'
    const tw_title = evaluateFlags(useMainTitleForTW, model?.title, seo?.tw_title)
    const tw_description = evaluateFlags(useMainDescriptionForTW, model?.description, seo?.tw_description)
    const tw_image = evaluateFlags(useMainImageForTW, getImageData(model?.banner_image), getImageData(seo?.tw_image))
    const seo_result = {
        url, title, description, image,
        og_type, og_title, og_description, og_image,
        tw_card_type, tw_title, tw_description, tw_image,
    }
    return { seo: seo_result }

}
