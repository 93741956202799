import React, { Fragment, useEffect, useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'

const HighlightImage = (props) => {
    
    const {
        image: _image,
        imageCard,
        settings,
    } = props
    const { token } = useAuth()
    const [image, setImage] = useState()
    const isVertical = imageCard === 'vertical'
    const direction = isVertical ? `vertical` : `horizontal`
    const { alt, url } = image || {}
    const { image_settings } = settings || {}
    const { options } = image_settings || {}
    const { position = 'center center' } = options || {}
    const image_style = {
        backgroundImage: `url(${url})`,
        backgroundPosition: position,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }
    
    const getImage = async () => {
        if (_image) {
            const img = await SimpliCitySDK.images.findOne(token, _image)
            setImage(img)
        }
    }
    
    useEffect(() => {
        getImage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_image])
    
    
    return (
        <>
            {image &&
                <div
                    className={`simplicity-cta-content-image ${direction}`}
                    style={image_style}
                    title={alt}
                />
            }
        </>
    )
}

export { HighlightImage }
