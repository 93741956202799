import { lazy } from 'react'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { Layout_Homepage } from 'layout/homepage'
import { Logger } from 'cms/utils/logger'
const Layout_Event = lazy(() => import('layout/event/event'))
const Layout_Meeting = lazy(() => import('layout/meeting/meeting'))
const Layout_Calendar = lazy(() => import('layout/event/calendar'))
const Layout_Neighbourhood = lazy(() => import('layout/neighbourhood'))
const Layout_FullWidthSingle = lazy(() => import('layout/full-width-single'))
const Layout_Integration = lazy(() => import('layout/integration'))
const Layout_Integration_w_SideNav = lazy(() => import('layout/integration-w-side-nav'))
const Layout_NewsTypes = lazy(() => import('layout/news-types'))
const Layout_Permit = lazy(() => import('layout/permit/permit'))
const Layout_Report = lazy(() => import('layout/report/report'))
const Layout_ReportSection = lazy(() => import('layout/report/report-section'))
const Layout_SectionLandingPage = lazy(() => import('layout/section-landing-page'))
const Layout_Story = lazy(() => import('layout/story'))
const Layout_DevelopmentApp = lazy(() => import('layout/development-app/dev-app'))
const Layout_TwoColumnSideNav = lazy(() => import('layout/two-column-side-nav/two-col-side-nav'))
const Layout_NavSubsection = lazy(() => import('layout/nav-subsection'))
const Layout_TwoColumnNoNav = lazy(() => import('layout/two-column-no-nav'))
const Layout_StoryFeed = lazy(() => import('layout/story-feed'))

const LayoutSelector = () => {
    const { webpage, model } = useSimpliCity()

    const getLayoutComponent = (webpage, model) => {
        // TODO: temporarily forcing layout by certain model types
        const { type } = webpage || {}
        const { type: model_type } = model || {}
        Logger.debug(`Model Type is ${model_type}`)
        if (model_type === "model.news") {
            return <Layout_NewsTypes selectedSection="News" />
        }
        if (model_type === "model.notice") {
            return <Layout_NewsTypes selectedSection="Notices" />
        }
        if (model_type === "model.alert") {
            return <Layout_NewsTypes selectedSection="Alerts" />
        }
        // NOTE: FORCED LAYOUT
        if (model_type === "model.meeting") {
            return <Layout_Meeting />
        }
        // NOTE: FORCED LAYOUT
        if (model_type === "model.event") {
            return <Layout_Event />
        }
        // NOTE: FORCED LAYOUT
        if (model_type === "model.permit") {
            return <Layout_Permit />
        }
        // NOTE: FORCED LAYOUT
        if (model_type === "model.neighbourhood") {
            return <Layout_Neighbourhood />
        }
        // NOTE: FORCED LAYOUT
        if (model_type === "model.development-application") {
            return <Layout_DevelopmentApp />
        }
        Logger.debug(`Layout was ${type}`)
        
        switch (type) {
            case "fullwidth":
                return <Layout_FullWidthSingle />
            case "two-column-nav":
                return <Layout_TwoColumnSideNav />
            case "two-column-no-nav":
                return <Layout_TwoColumnNoNav />
            case "nav-subsection":
                return <Layout_NavSubsection />
            case "section-landing-page":
                return <Layout_SectionLandingPage />
            case "dev-app-page":
                return <Layout_DevelopmentApp />
            case "homepage":
                return <Layout_Homepage />
            case "event-page":
                return <Layout_Event />
            case "meeting-page":
                return <Layout_Meeting />
            case "calendar":
                return <Layout_Calendar />
            case "neighbourhood":
                return <Layout_Neighbourhood />
            case "permit":
                return <Layout_Permit />
            case "report":
                return <Layout_Report />
            case "report-section":
                return <Layout_ReportSection />
            case "story":
                return <Layout_Story />
            case "story-feed":
                return <Layout_StoryFeed />
            case "integration":
                return <Layout_Integration />
            case "integration-nav":
                return <Layout_Integration_w_SideNav />
            default:
                return <Layout_FullWidthSingle />
        }
    };

    const component = getLayoutComponent(webpage, model)

    return <div>{component}</div>
}

LayoutSelector.displayName = "LayoutSelector"
export { LayoutSelector }
