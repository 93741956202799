
import  {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faXTwitter,
    faYoutube,
    // faTwitter,
// } from '@awesome.me/kit-f6a7b16729/icons/classic/brands'
} from '@awesome.me/kit-5f107d6f8b/icons/classic/brands'


const brands = {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faXTwitter,
    faYoutube,
    // faTwitter,
}

export {
    brands,
}
