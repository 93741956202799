import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Logger } from 'cms/utils/logger'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { FormSuccess } from 'cms/forms/success'
import { useFormSettings } from 'cms/forms/use-form-settings'
import { useGetRecaptchaToken } from 'cms/forms/use-get-recaptcha-token'
import { useSimpliCityPath } from 'cms/webpage'
import { FeedbackButton } from './feedback-button'
import { getFeedbackQuestion } from './feedback-config'

const FeedbackHelpful = () => {
    
    // NOTE: Warning: component can not be used without the context
    const { webpage, model } = useSimpliCity()
    const { path } = useSimpliCityPath()
    const { settings } = useSimpliCity()
    const { token } = useAuth()
    //TODO: for after switch to model features
    // const { features } = model || {}
    // const { feedback_form } =  features || {}
    
    const form_guid = settings?.feedback_form_guid
    const hideFeedback = !webpage || !webpage?.show_feedback_form
    const url = window.location.href

    const [response, setResponse] = useState()
    const [otherFeedback, setOtherFeedback] = useState()
    const [completed, setCompleted] = useState(false)

    const { value: formConfig } = useFormSettings(form_guid)
    const { getRecaptchaToken } = useGetRecaptchaToken(formConfig?.settings?.enable_captcha, 'feedback_form')

    const onSubmitFeedback = async () => {
        
        Logger.info(`[FeedbackHelpful] onSubmitFeedback`)
        const fields = []
        fields.push(getFeedbackQuestion("feedback_approve", response))
        fields.push(getFeedbackQuestion("feedback_other_feedback", otherFeedback))
        fields.push(getFeedbackQuestion("feedback_title", model?.title)) // may not be avail
        fields.push(getFeedbackQuestion("feedback_url", url))
        fields.push(getFeedbackQuestion("feedback_path", path))
        fields.push(getFeedbackQuestion("feedback_webpage", webpage?.guid)) // may not be avail
        fields.push(getFeedbackQuestion("feedback_model", model?.guid)) // may not be avail

        const payload = {
            form_guid,
            fields,
        }
        Logger.info(`[FeedbackHelpful] onSubmitFeedback payload`, payload)
        
        const recaptchaToken = await getRecaptchaToken()
        await SimpliCitySDK.formResponse.create(
            token,
            payload,
            { headers: { 'x-recaptcha-token': recaptchaToken } }
        )
        resetForm()
    }
    
    const resetForm = () => {
        setResponse(undefined)
        setOtherFeedback(undefined)
        setCompleted(true)
    }

    const onClickYes = () => {
        Logger.info(`[FeedbackHelpful] yes clicked`)
        const value = 'yes'
        setResponse(value)
    }
    
    const onClickNo = () => {
        Logger.info(`[FeedbackHelpful] no clicked`)
        const value = 'no'
        setResponse(value)
    }

    const onClickClear = (event) => {
        Logger.info(`[FeedbackHelpful] clear clicked`)
        event.stopPropagation()
        setResponse(undefined)
        setOtherFeedback(undefined)
        setCompleted(false)
    }

    const onChangeOtherFeedback = (event) => {
        event.stopPropagation()
        const value = event.target.value
        setOtherFeedback(value)
    }
    
    if (hideFeedback) {
        return null
    }
    
    //TODO: for after switch to model features
    // if (!feedback_form) {
    //     return null
    // }
    
    return (
        <>
            {response === undefined && !completed && (
                <div className='page-feedback__block'>
                    <div className='page-feedback__header'>
                        <h2 className="page-feedback__title">Was this page helpful?</h2>
                        <div className='page-feedback__options'>
                            <FeedbackButton label='Yes' onClick={onClickYes} isSelected={response === 'yes'} />
                            <FeedbackButton label='No' onClick={onClickNo} isSelected={response === 'no'} />
                        </div>
                    </div>
                </div>
            )}

            {response !== undefined && !completed && (
                <div className='page-feedback__block'>
                    <div className='page-feedback__header'>
                        <h2 className='page-feedback__title'>Was this page helpful?</h2>
                        <div className='page-feedback__options'>
                            <FeedbackButton label='Yes' onClick={onClickYes} isSelected={response === 'yes'} />
                            <FeedbackButton label='No' onClick={onClickNo} isSelected={response === 'no'} />
                        </div>
                    </div>

                    <hr />

                    {response === 'no' &&
                        <div className='feedback-response'>
                            <div className='feedback-titlebar'>
                                <p className='feedback-instruction'>What was missing? (optional)</p>

                                <button
                                    className='feedback-close'
                                    aria-expanded='true'
                                    aria-label='Close the feedback form'
                                    onClick={onClickClear}
                                >
                                    <FontAwesomeIcon icon={`fa-regular fa-xmark`} />
                                </button>
                            </div>
                            <div className='feedback-textarea'>
                                <textarea
                                    id='comments_no'
                                    onChange={onChangeOtherFeedback}
                                    className='feedback-form-textarea'
                                    value={otherFeedback}
                                />
                            </div>
                        </div>
                    }

                    {response === 'yes' &&
                        <div className='feedback-response'>
                            <div className='feedback-titlebar'>
                                <p className='feedback-instruction'>What worked well for you? (optional)</p>

                                <button
                                    className='feedback-close'
                                    aria-expanded='true'
                                    aria-label='Close the feedback form'
                                    onClick={onClickClear}
                                >
                                    <FontAwesomeIcon icon={`fa-regular fa-xmark`} />
                                </button>
                            </div>
                            <div className='feedback-textarea'>
                                <textarea
                                    id='comments_yes'
                                    onChange={onChangeOtherFeedback}
                                    className='feedback-form-textarea'
                                    value={otherFeedback}
                                />
                            </div>
                        </div>
                    }

                    {response &&
                        <div className='feedback-actions'>
                            <button
                                onClick={onSubmitFeedback}
                                className='btn webform-submit button-primary form-submit'
                                type='submit'
                                value='Submit'
                            >
                                Submit
                                <ArrowForwardIcon />
                            </button>
                        </div>
                    }
                </div>
            )}

            {completed &&
                <div className='simplicity-forms'>
                    <FormSuccess />
                </div>
            }
        </>
    )
}

FeedbackHelpful.displayName = 'FeedbackHelpful'
export { FeedbackHelpful }
