import { SimpliCitySDK } from "cms/sdk";
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync, useCounter } from 'react-use'

const defaultPage = 1
const defaultPageSize = 10

/**
 * @param {string} type
 * @param {string} categoryName
 * @param selectedCategory
 */
const useFetchFeed = (type, categoryName, selectedCategory) => {
    const { token } = useAuth()

    const [term, setTerm] = useState('')
    const [category, setCategory] = useState(selectedCategory ?? '')
    useEffect(() => {
        setCategory(selectedCategory)
    }, [selectedCategory])
    
    const [page, { inc, dec, reset, set }] = useCounter(defaultPage, null, 1)
    const pageSize = defaultPageSize

    const { loading, error, value } = useAsync(async () => {
        let sort, direction
        // if (type === 'model.bylaw' || type === 'model.permit' || type === 'model.development-application' ) {
        const sortedTypes = ['model.bylaw' ,'model.permit', 'model.development-application', 'model.committee']
        if (sortedTypes.includes('type')) {
            sort = 'title'
            direction = 'asc'
        }
        const options = { page, pageSize, type, term, sort, direction }

        if (category) {
            if (category !== 'All' && category !== "- Any -") {
                options.category = category
                options.categoryName = categoryName
            }
        }
        const { pagination, results: models } = await SimpliCitySDK.model.findFeed(token, options)
        
        return {
            models,
            pagination
        }
    }, [token, page, categoryName, term, category])

    const search = useCallback(
        /**
         * @param {string} term 
         * @param {string} category 
         */
        (term, category) => {
            setTerm(term)
            setCategory(category)
            reset()

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )

    const paginationState = useMemo(() => {
        const total = value?.pagination?.total || 0
        const numPages = Math.ceil(total / pageSize)
        const hasPrev = page > 1
        const hasNext = page < numPages

        const first = ((page - 1) * pageSize) + 1
        const last = Math.min((page) * pageSize, total)
        const hasResults = total > 0

        return {
            first,
            hasNext,
            hasPrev,
            hasResults,
            last,
            numPages,
            page,
            pageSize,
            total,
        }
    }, [page, pageSize, value?.pagination?.total])

    const controls = {
        next: () => inc(),
        prev: () => dec(),
        to: set,
    }

    return {
        // state
        loading,
        error,
        value,

        // pagination
        pagination: {
            controls,
            state: paginationState
        },
        search,
    }
}

export { useFetchFeed }
