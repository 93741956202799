import { useForm } from 'cms/forms/context'
import { useEffect } from 'react'
// import { Logger } from 'cms/utils/logger'

/**
 * Set question errors for the given question
 * @param {string} guid 
 * @param {string | string[] | null} error 
 */
export function useSetQuestionErrors (guid, error) {
    const { setQuestionError } = useForm()

    useEffect(() => {
        // Logger.debug(`Setting question error ${guid} => ${error}`, { error })
        setQuestionError(guid, error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guid, error])
}
